import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import router from './router'
import UserData from './utils/UserData'
import {isMobile} from "./utils/common";
Vue.prototype.$UserData = UserData
// let isMobileDevice = isMobile()
Vue.prototype.$isMobile =isMobile() //isMobileDevice
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
