import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserId } from "@/utils/store";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/chatai',
        name: 'chatai',
        component: () =>
            import ('../views/AIQuestion.vue'),
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/login/index.vue'),
        meta: {
            requiresAuth: false,
            title: 'FBLA-ECOG'
        },
    },
    {
        path: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        }
    },
    {
        path: '/home',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/Home.vue'),
    },
    {
        path: '/students',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/ContactHome.vue'),
    },
    {
        path: '/classes',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/ClassList.vue'),
    },
    {
        path: '/exam',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/StudentExam.vue'),
    },
    {
        path: '/errors',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/ErrorList.vue'),
    },
    {
        path: '/error-statistics',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/ErrorStatistics.vue'),
    },
    {
        path: '/put-question',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/PutQuestion.vue'),
    },
    {
        path: '/collection-list',
        meta: {
            requiresAuth: true,
            title: 'FBLA-ECOG'
        },
        component: () =>
            import ('../views/CollectionList.vue'),
    },
]
const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router