import Cookies from 'js-cookie'

const USER_ID = 'fbla-ecog_user_id'
const USER_EMAIL = 'fbla-ecog_user_email'
const USER_NAME = 'fbla-ecog_user_name'
const ACCOUNT_ID = 'fbla-ecog_account_id'
const USER_TYPE = 'fbla-ecog_user_type'

export function setUserType(user_type) {
    Cookies.set(USER_TYPE, user_type)
}

export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function setAccountId(account_id) {
    Cookies.set(ACCOUNT_ID, account_id)
}

export function getAccountId() {
    return Cookies.get(ACCOUNT_ID)
}

export function setUserEmail(email) {
    Cookies.set(USER_EMAIL, email)
}

export function getUserEmail() {
    return Cookies.get(USER_EMAIL)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function clearUser() {
    Cookies.remove(USER_EMAIL)
    Cookies.remove(USER_ID)
    Cookies.remove(USER_NAME)
    Cookies.remove(ACCOUNT_ID)
    Cookies.remove(USER_TYPE)
}
