<template>
  <div id="app">
    <header class="header" v-if="isShowHeader">
      <div class="view-root">
        <div class="header-container">
          <div style="flex: 1">
            <img
              src="./assets/home_logo.png"
              style="
                width: 136px;
                height: 32px;
                object-fit: cover;
                margin-left: 24px;
              "
            />
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            "
            @click="logout"
          >
            <p class="user-style">Hi, {{ this.$UserData.user_name }}</p>
            <img
              src="./assets/exit.png"
              style="
                width: 20px;
                height: auto;
                object-fit: cover;
                margin-left: 15px;
                margin-right: 32px;
              "
            />
          </div>
        </div>
      </div>
    </header>
    <el-container style="margin: 0px; padding: 0px">
      <el-main style="margin: 0px; padding: 0px">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { clearUser, getUserName } from "./utils/store";
import "./assets/common/common.css";

export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    this.$UserData.user_name = getUserName();
  },

  computed: {
    isShowHeader() {
      let path = this.$route.path;
      return path.search("login") <= 0 && path.search("chatai") <= 0;
    },
  },
  methods: {
    logout() {
      this.$alert("Are you sure you want to log out？", "Notice", {
        callback: (action) => {
          if (action == "confirm") {
            clearUser();
            location.reload();
          }
        },
      });
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>
<style scoped lang="less">
.header-container {
  margin: 0 auto;
  height: 8vh;
  background-color: #092e7f;
  background-image: url("./assets/header_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.user-style {
  font-size: 22px;
  font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
  font-weight: 500;
  color: #ffffff;
}
</style>
